import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from "./ui/button"
import { Avatar } from "./ui/avatar"
import { User, Settings, CreditCard, HelpCircle, LogOut, ChevronRight } from 'lucide-react'
import { cn } from "../lib/utils"

interface ProfileMenuProps {
  isCollapsed?: boolean
}

export function ProfileMenu({ isCollapsed = false }: ProfileMenuProps) {
  return (
    <Menu as="div" className="relative">
      <Menu.Button as="div">
        <div
          className={cn(
            "flex items-center gap-2 rounded-md p-2 cursor-pointer hover:bg-accent",
            isCollapsed ? "justify-center" : "w-full"
          )}
        >
          <div className="flex-shrink-0 w-9 h-9">
            <Avatar
              src="https://pbs.twimg.com/profile_images/1600655159687716864/Je9m8YDl_400x400.jpg"
              alt="Profile"
              fallback="AB"
              className="w-full h-full"
            />
          </div>
          {!isCollapsed && (
            <>
              <div className="flex-1 text-left">
                <div className="font-medium">Archie Bunker</div>
                <div className="text-sm text-muted-foreground">caleb.leigh@corsair.com</div>
              </div>
              <ChevronRight className="h-4 w-4 text-muted-foreground" />
            </>
          )}
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-full bottom-0 ml-2 w-56 divide-y divide-border rounded-md bg-background shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={cn(
                    "flex w-full items-center rounded-md px-2 py-2 text-sm",
                    active ? "bg-accent text-accent-foreground" : "text-foreground"
                  )}
                >
                  <User className="mr-2 h-4 w-4" />
                  Profile
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={cn(
                    "flex w-full items-center rounded-md px-2 py-2 text-sm",
                    active ? "bg-accent text-accent-foreground" : "text-foreground"
                  )}
                >
                  <Settings className="mr-2 h-4 w-4" />
                  Stream Deck Settings
                </button>
              )}
            </Menu.Item>
          </div>
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={cn(
                    "flex w-full items-center rounded-md px-2 py-2 text-sm",
                    active ? "bg-accent text-accent-foreground" : "text-foreground"
                  )}
                >
                  <CreditCard className="mr-2 h-4 w-4" />
                  Subscription
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={cn(
                    "flex w-full items-center rounded-md px-2 py-2 text-sm",
                    active ? "bg-accent text-accent-foreground" : "text-foreground"
                  )}
                >
                  <HelpCircle className="mr-2 h-4 w-4" />
                  Help & Support
                </button>
              )}
            </Menu.Item>
          </div>
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={cn(
                    "flex w-full items-center rounded-md px-2 py-2 text-sm",
                    active ? "bg-accent text-accent-foreground" : "text-foreground"
                  )}
                >
                  <LogOut className="mr-2 h-4 w-4" />
                  Log out
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}